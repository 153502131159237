import React from "react";
import Banner from "../components/Banner";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Cta from "../components/service/Cta";
import ServiceTab from "../components/ServiceTab";
import Clients from "../components/service/Clients";
import Testimonials from "../components/Testimonials";
import ProjectSection from "../components/ProjectSection";
import Faq from "../components/Faq";
// import KnowMoreSection from "../components/KnowMoreSection";
import { Seo } from "../components/seo";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";

const ServiceDetail = () => {
  const banner = {
    title: `<span class="h1-span">Efficient Design </span><span class="h1-span">System Agency</span>`,
    content:
      "Our design system agency delivers cohesive and efficient design solutions that ensure consistency across all components, enhancing your brand’s visual and functional integrity.",
  };

  const commonContent6 = {
    title: "Comprehensive Design <span class='h1-span'>System Services</span>",
    para: "With our design system services, we analyze your business, audience, and market to provide comprehensive design solutions. As your design system consultant, we ensure that every detail resonates with your audience and reflects current trends.",
    img: ["what-service-image-designsys.webp"],
    imageTitle: "Design System Engineering",
    imageAlt: "Design System as a Service",
    content: [
      {
        title: "Design System Audit",
        text: "We conduct a comprehensive design audit to identify the design potential and enhance your business performance.",
      },
      {
        title: "Product Design",
        text: "We design user-centric product experiences to leave a lasting impact on users’ minds and drive success.",
      },
      {
        title: "Web and Mobile Design",
        text: "We create designs that seamlessly integrate across all web and mobile platforms, ensuring consistent user experience.",
      },
      {
        title: "Brand Design System",
        text: "We craft a unified design system that embodies your brand’s essence and elevates your brand identity.",
      },
      {
        title: "Enterprise Design Systems",
        text: "We provide scalable and functional design solutions to large organizations, ensuring consistency and efficiency. ",
      },
      {
        title: "Training & Workshop",
        text: "We also empower your team with design knowledge and skills with our training programs and workshops.",
      },
    ],
  };
  const commonContent = {
    title: "Design System Agency <span class='h1-span'>Transforming Industries</span>",
    para: "Design System Engineering Agencies are integral to every industry looking for sustained growth. With the expertise of a design system engineer, they ensure that every aspect of your design resonates with your audience and reflects current trends.",
    img: ["who-needs-designsys.webp"],
    imageTitle: "Design System Engineering Services",
    imageAlt: "who-needs-designsys",
    content: [
      {
        title: "SaaS Companies",
        text: "Companies looking to establish consistent and unified user experience to increase adoption rates.",
      },
      {
        title: "Tech Companies",
        text: "Companies who want to enhance their product’s usability through innovative technologies and rapid iterations.",
      },
      {
        title: "Cloud Companies",
        text: "Companies looking to simplify their complex functions, ensure seamless integration, and enhance productivity.",
      },
      {
        title: "Freight Companies",
        text: "Companies looking to maintain a smooth business process and track live performance through simplified data.",
      },
    ],
  };

  const commonContent2 = {
    title: "Benefits of Design System <span class='h1-span'> as a Service</span>",
    para: "Design System Services fosters business recognition and provides a competitive edge in the digital landscape. It is an effective and efficient process that streamlines the design and development process for long-term business growth.",
    img: ["what-are-benefits-designsys.webp"],
    imageTitle: "Benefits of Design System Engineering",
    imageAlt: "design system services",
    content: [
      {
        title: "Scalable Solution",
        text: "Design system services offer flexible solutions that can accommodate new features to your design based on market trends.",
      },
      {
        title: "Seamless Integration",
        text: "Design system engineers can seamlessly integrate design systems with the existing workflow for increased productivity.",
      },
      {
        title: "Periodical Updates",
        text: "The design system offers innovative solutions using modern technologies and keeps updating with the latest design trends.",
      },
      {
        title: "Enhanced User Experience",
        text: "Design system services offer a seamless, interactive, intuitive interface to keep users engaged and offer an exceptional user experience.",
      },
    ],
  };
  const commonContent3 = {
    title: "Why Trust Octet as your <span class='h1-span'>Design System Consultancy?</span>",
    linkText: ``,
    para: `We are an innovative UI UX design company with a team of experienced design system consultants who understand design intricacies and offer holistic and future-proof design solutions with a client-centric approach.`,
    img: ["why-opt-for-designsys.webp"],
    imageTitle: "Design System Engineering Company",
    imageAlt: "design system consultant",
    content: [
      {
        title: "Expertise and Experience",
        text: "We have a team of experienced design system consultants who have worked with industries and use their expertise to deliver exceptional design systems.",
      },
      {
        title: "Holistic Approach",
        text: "We cover every aspect of the design system to create integrated and balanced solutions to address your business requirements.",
      },
      {
        title: "Tailor-made Solutions",
        text: "We focus on a collaborative approach to receive feedback and deliver customized design solutions to meet your business goal and project objective.",
      },
      {
        title: "Assured Quality",
        text: "We conduct rigorous testing led by our design system engineer to ensure our solutions meet the expected outcome and deliver high-quality results.",
      },
    ],
  };
  const commonContent4 = {
    title: "Our Design System <span class='h1-span'>Service Process</span>",
    para: "We utilize a collaborative approach, leveraging shared insights and our expertise to craft cohesive design systems as a service and ensure exceptional user experiences.",
    img: ["how-we-conduct-designsys.webp"],
    imageTitle: "Process of Design System Engineering",
    imageAlt: "building design systems",
    content: [
      {
        title: "1. Research & Define",
        text: "Every aspect of our design process is influenced by research, and so is the design system. We care to align the design system with users and the client's requirements.",
      },
      {
        title: "3. Build",
        text: "We use the JavaScript framework to develop the design system components that will be used further in UI development.",
      },
      {
        title: "2. Design",
        text: "After a thorough audit, we start designing the design systems. All elements are carefully designed, and the new system is communicated to the design team.",
      },
      {
        title: "4. Audit & Refine",
        text: "We audit the existing design system to identify key improvement areas and refine any irrelevant components.",
      },
    ],
  };
  const commonContent5 = {
    title: "Gains of Investing in <span class='h1-span'>Design System Consultancy</span>",
    para: "Design System Engineering is a long-term investment that saves time and cost by fostering a culture of reusability and scalability, rapid iterations, and high-quality designs.",
    content: [
      {
        title: "37% Less Development Time",
        text: "Design System offers pre-built and reusable components that save time to create components during development.",
      },
      {
        title: "23% Increased Revenue",
        text: "Design System fastens the development process and improves user experience, leading to increased returns.",
      },
      {
        title: "18% Improved Product Quality",
        text: "Design System Engineering promotes consistency, usability and scalability of components that result in improved quality..",
      },
      {
        title: "35% Reduced Cost",
        text: "Design System minimizes the cost of creating new components for every design, streamlining the process.",
      },
    ],
    desc: "**These data are from public sources; we do not claim to owe these stats.",
  };
  const cta = {
    title: "Optimise UX With Design <span class='h1-span'>Systems Consultancy!</span>",
  };
  const cta2 = {
    title: "Let us Build Our <span class='h1-span'>Design System Engineer!</span>",
  };
  const cta3 = {
    title: "Drive Success with Our <span class='h1-span'>Design System Consultant!</span>",
  };
  const cta4 = {
    title: "Transform your Design <span class='h1-span'>Ecosystem Today!</span>",
  };
  const cta5 = {
    title: "Collaborate with our <span class='h1-span'>Design System Engineers!</span>",
  };
  const tabs = [
    {
      link: "/angularjs-development/",
      text: "Angular Development",
    },
    {
      link: "/branding/",
      text: "Branding",
    },
    {
      link: "/corporate-training-workshop/",
      text: "UI UX Workshops and Training",
    },
    {
      link: "/data-visualisation/",
      text: "Dashboards and Data Visualisation",
    },
    {
      link: "/design-audit/",
      text: "Design Audit ",
    },
    {
      link: "/design-systems/",
      text: "Design System Engineering",
    },
    {
      link: "/digital-transformation/",
      text: "Digital Transformation",
    },
    {
      link: "/product-development/",
      text: "Product Development",
    },
    {
      link: "/rapid-prototyping/",
      text: "Rapid Prototyping",
    },
    {
      link: "/reactjs-development/",
      text: "ReactJS Development",
    },
    {
      link: "/ui-development/",
      text: "UI Development",
    },
    {
      link: "/ui-ux-designing/",
      text: "UI UX Designing",
    },
    {
      link: "/usability-testing/",
      text: "Usability Testing",
    },
    {
      link: "/user-acquisition-consulting/",
      text: "User Acquisition Consultation",
    },
    {
      link: "/user-research/",
      text: "User Research",
    },
    {
      link: "/ux-consulting/",
      text: "UX Consultation",
    },
    {
      link: "/vuejs-development/",
      text: "VueJS Development",
    },
    {
      link: "/wordpress-development/",
      text: "WordPress Development",
    },
  ];
  const projectData = [
    {
      title: "Kantime",
      desc: "A Hospice EMR Solutions Platform",
      Industry: "HealthTech",
      link: "/project/kantime/",
      firstAlt: "design system development",
      firstTitle: "Performance and Schedule Screen",
      secondAlt: "Design System Consulting",
      secondTitle: "Accounts Details",
      WhatWeDid: [
        {
          title: "UX Consultation",
          link: "/ux-consulting/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Design System Engineering",
          link: "/design-systems/",
        },
      ],
      images: ["kantime-project.webp", "kantime-project-2.webp"],
    },
    {
      title: "Survey2Connect",
      desc: "A Research Tech Platform",
      Industry: "Enterprise",
      firstAlt: "",
      firstTitle: "Survey Creation Screen",
      secondAlt: "",
      secondTitle: "Questionnaire For Survey",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "UI Development",
          link: "/ui-development/",
        },
      ],
      link: "/project/survey2connect/",
      images: ["survey.webp", "survey-2.webp"],
    },
  ];

  const Questions = [
    "What are the design system services you offer?",
    "Can you customize the design system based on our business needs?",
    "Can you implement a design system in our existing design workflow?",
    "What kind of support do you offer during the design system process?",
    "How do I measure the success of design system services?",
    "What is your timeline to develop and implement the design system?",
  ];
  const clientsTitle = "Elevating Brands With Our <span class='h1-span'>Design System Services</span>";
  const faqDes = `We understand you might have more questions about our design system engineering agency. In this section, we answered some common questions asked by our clients. If you still have doubts, please <a href="/contact-us/">contact us</a>.`;

  const faqData = [
    {
      para: [
        `We offer holistic design system services to create customized solutions for your business needs. Our team of expert designers ensures that our design system meets your business requirements and boosts efficiency.`,
      ],
      list: [
        `<span><h4 class="inline">Design System Foundation</h4></span> - We thoroughly research the product and define all elements and components for companies at an early stage.`,
        `<span><h4 class="inline">Extensive Design System</h4></span> - We build custom components and provide appropriate data visuals, graphs, etc.`,
        `<span><h4 class="inline">Multi-Language Engineering</h4></span> - We also translate the design systems into codes of the design system in different programming languages.`,
        `<span><h4 class="inline">Multi-Platform Engineering</h4></span> - We create separate design systems for both mobile and desktop applications.`,
      ],
      para2: [
        `We also offer services on <a href="/branding/">Branding</a> Strategy Consultants,  <a href="/user-research/">User Experience Research</a> Services, etc. `,
      ],
    },
    {
      para: [
        `Yes, being a design system agency, we understand that every business has different product offerings, project requirements, and business goals.`,
        `We aim to create a customized design system for your business based on our market research, trends, target audience, business objectives, etc.`,
        `Our focus lies on a collaborative approach to understanding shared insights and also considers your existing design assists for increased flexibility.`,
      ],
    },
    {
      para: [
        `Yes, we can seamlessly integrate your existing design system with our current design flow.`,
        `We keep ourselves updated with the new tools and processes for integration for increased productivity.`,
        `Our aim is to improve your customer experience by creating an intuitive and visually appealing design system.`,
      ],
    },
    {
      para: [
        `We ensure a smooth design process and seamless implementation of our design system.`,
        `We guide you throughout the design system service and provide post-project support to fulfill your requirements.`,
        `We also provide updated solutions based on market trends and evolving technologies.`,
      ],
    },
    {
      para: [
        `We measure the success of our design system engineering services by evaluating user satisfaction, cost-effectiveness, etc.`,
        `Additionally, assessing the time and cost involved in the development, reuse of design components, and potential cost savings are other factors that track the success of our design system services.`,
      ],
    },
    {
      para: [
        `The timeline for creating and implementing the design system varies on various factors, such as business goals and project requirements.`,
        `Our design system services can range from a few weeks to several months. However, for a detailed timeline estimation, we recommend you <a href="/contact-us/">contact us</a>.`,
      ],
    },
  ];

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [{
                "@type": "Question",
                "name": "1. What are the design system services you offer?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "We offer holistic design system services to create customized solutions for your business needs. Our team of expert designers ensures that our design system meets your business requirements and boosts efficiency.
            Design System Foundation
            We thoroughly research the product and define all elements and components for companies at an early stage.
            Extensive Design System
            We build custom components and provide appropriate data visuals, graphs, etc.
            Multi-Language Engineering
            We also translate the design systems into codes of the design system in different programming languages.
            Multi-Platform Engineering
            We create separate design systems for both mobile and desktop applications.
            
            We also offer services on Branding Strategy Consultants, User Experience Research Services, etc."
                }
              },{
                "@type": "Question",
                "name": "2. Can you customize the design system based on our business needs?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes, being a design system agency, we understand that every business has different product offerings, project requirements, and business goals. 
            
            We aim to create a customized design system for your business based on our market research, trends, target audience, business objectives, etc. 
            
            Our focus lies on a collaborative approach to understanding shared insights and also considers your existing design assists for increased flexibility."
                }
              },{
                "@type": "Question",
                "name": "3. Can you implement a design system in our existing design workflow?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes, we can seamlessly integrate your existing design system with our current design flow. 
            
            We keep ourselves updated with the new tools and processes for integration for increased productivity. 
            
            Our aim is to improve your customer experience by creating an intuitive and visually appealing design system."
                }
              },{
                "@type": "Question",
                "name": "4. What kind of support do you offer during the design system process?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "We ensure a smooth design process and seamless implementation of our design system. 
            
            We guide you throughout the design system service and provide post-project support to fulfill your requirements. 
            
            We also provide updated solutions based on market trends and evolving technologies."
                }
              },{
                "@type": "Question",
                "name": "5. How do I measure the success of design system services?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "We measure the success of our design system engineering services by evaluating user satisfaction, cost-effectiveness, etc. 
            
            Additionally, assessing the time and cost involved in the development, reuse of design components, and potential cost savings are other factors that track the success of our design system services."
                }
              },{
                "@type": "Question",
                "name": "6. What is your timeline to develop and implement the design system?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "The timeline for creating and implementing the design system varies on various factors, such as business goals and project requirements. 
            
            Our design system services can range from a few weeks to several months. However, for a detailed timeline estimation, we recommend you contact us."
                }
              }]
            }                                          
          `}
        </script>
      </Helmet>
      <Layout>
        <div className="service-detail-page">
          <Banner content={banner} page={"service"} />
          <CommonServiceComponent data={commonContent6} mode={"grey"} />
          <ServiceTab tabs={tabs} />
          <CommonServiceComponent data={commonContent} mode={"light"} />
          <Cta mode="grey" data={cta} />
          <CommonServiceComponent data={commonContent2} mode={"dark"} />
          <CommonServiceComponent data={commonContent3} mode={"light"} />
          <Cta data={cta2} />
          <Clients title={clientsTitle} />
          <ServiceTab tabs={tabs} />
          <CommonServiceComponent data={commonContent4} mode={"light"} />
          <Cta mode="grey" data={cta3} />
          <section className="project-section lg:py-[200px] md:py-[120px] py-[80px] text-white bg-[url('../images/guides.webp')] bg-[length:100%] bg-repeat-y bg-top bg-black-main">
            <div className="container">
              <h2 className="lg:mb-[80px] md:mb-[40px] mb-[30px] font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36]">
                Our Design System <span class='h1-span'>Engineering Work</span>
              </h2>
            </div>
            {projectData.map((data, index) => (
              <div key={index}>
                <ProjectSection
                  num={index}
                  lastLength={projectData}
                  data={data}
                />
              </div>
            ))}
          </section>
          <Testimonials title={"Proven Success of Our Design System Agency"} />
          <Cta data={cta4} />
          <CommonServiceComponent
            data={commonContent5}
            condition
            mode={"light"}
          />
          <ServiceTab tabs={tabs} mode={"split"} />
          <Faq
            Questions={Questions}
            section
            des={faqDes}
            link
            faqData={faqData}
          />
          <Cta mode="grey" data={cta5} />
          {/* <KnowMoreSection /> */}
        </div>
      </Layout>
    </>
  );
};

export default ServiceDetail;

export const Head = () => (
  <Seo
    title="Design System Agency | Design System as a Service"
    description="Our design system agency specializes in creating custom, scalable design solutions to elevate your digital presence. Explore our design system expertise today."
  />
);
